<template>
  <div>
    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'browse-questions' }">
              Questions
            </b-breadcrumb-item>
            <b-breadcrumb-item :active="true">
              Manage Questions
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </b-col>
      <b-col md="6">
        <h2 class="p-2">
          <feather-icon
            icon="AwardIcon"
            size="24"
          />
          Manage Questions
        </h2>
      </b-col>
      <b-col
        md="6"
        class="mb-2 text-right pt-2"
      >
        <b-button
          variant="primary"
          :to="{ name: 'create-question' }"
        >
          <feather-icon
            icon="PlusIcon"
            size="16"
            class="align-text-top"
          />  Add New
        </b-button>
      </b-col>
    </b-row>

    <!-- error message -->
    <section v-if="errorMessage">
      <b-card>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            {{ errorMessage }}
          </div>
        </b-alert>
      </b-card>
    </section>

    <!-- questions table -->
    <section v-if="visibleQuestions">
      <!-- table row  -->
      <b-card>
        <b-row v-if="visibleQuestions.length <= 0">
          <b-col>
            No items to show.
          </b-col>
        </b-row>
        <b-row v-if="visibleQuestions.length > 0">
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="perPageSelect"
              >Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
                @change="handlePerPage"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            sm="8"
            offset="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="sortBySelect"
              >Sort</label>
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                  class="w-75"
                >
                  <template v-slot:first>
                    <option value="">
                      Select
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                  class="w-25"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="6"
            class="my-1"
          >
            <b-form-group class="mb-0">
              <label
                class="d-block text-sm-left"
                for="filterInput"
              >Filter</label>
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col> -->

          <!--
              :per-page="perPage"
              :current-page="currentPage"
          -->
          <b-col cols="12 mt-2">
            <b-table
              hover
              responsive
              :items="visibleQuestions"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(campaign_name)="data">
                <b-link :to="{ name: 'view-question', params: { id: data.item.id } }">
                  {{ data.item.campaign_name }}
                </b-link>
              </template>

              <template #cell(type)="data">
                <b-badge
                  pill
                  variant="light-primary"
                >
                  {{ data.item.type }}
                </b-badge>
              </template>

              <template #cell(open_date)="data">
                {{ convertToLocalTime(data.item.open_date) }}
              </template>

              <template #cell(cutoff_date)="data">
                {{ convertToLocalTime(data.item.cutoff_date) }}
              </template>

              <template #cell(close_date)="data">
                {{ convertToLocalTime(data.item.close_date) }}
              </template>

              <template #cell(closed)="data">
                <b-badge
                  pill
                  :variant="closedStatusColor[data.item.closed] || 'light-secondary'"
                >
                  <span v-if="data.item.closed">Closed</span>
                  <span v-else>Open</span>
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <b-dropdown-item
                      @click="handleItemTrue(data.item.id)"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span>Set as True</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="handleItemFalse(data.item.id)"
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        class="mr-50"
                      />
                      <span>Set as False</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="handleItemEdit(data.item)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                      />
                      <span>Edit Question</span>
                    </b-dropdown-item>

                    <!-- <b-dropdown-item
                      @click="handleItemDelete(data.item.id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                        class="mr-50"
                      />
                      <span>Delete</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </span>
              </template>

            </b-table>
          </b-col>

          <b-col
            cols="12"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTableRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
              @page-click="handlePaginationClick"
            />
          </b-col>
        </b-row>
      </b-card>
    </section>

    <!-- edit modal -->
    <b-modal
      id="modal-edit-question"
      centered
      title="Edit Question"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <validation-observer
          ref="editQuestionForm"
          #default="{invalid, dirty}"
        >
          <b-form
            class="form-container"
            @submit.prevent="handleModalSubmit"
          >
            <b-row>
              <b-col>
                <!-- question text -->
                <b-form-group
                  label="Question Text"
                  label-for="questionText"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Question Text"
                    rules="required"
                    vid="questionText"
                  >
                    <b-form-input
                      id="questionText"
                      v-model="questionForm.text"
                      type="text"
                      class="form-control"
                      placeholder="Question Text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                lg="4"
                md="6"
              >
                <!-- open date -->
                <b-form-group
                  label="Open Date"
                  label-for="openDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Open Date"
                    rules="required"
                    vid="openDate"
                  >
                    <flat-pickr
                      id="openDate"
                      v-model="questionForm.open_date"
                      name="openDate"
                      class="form-control"
                      :config="{ minuteIncrement: 1, enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
              >
                <!-- cutoff date -->
                <b-form-group
                  label="Cutoff Date"
                  label-for="cutoffDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cutoff Date"
                    rules="required"
                    vid="cutoffDate"
                  >
                    <flat-pickr
                      id="cutoffDate"
                      v-model="questionForm.cutoff_date"
                      name="cutoffDate"
                      class="form-control"
                      :config="{ minuteIncrement: 1, enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
                md="6"
              >
                <!-- close date -->
                <b-form-group
                  label="Close Date"
                  label-for="closeDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Close Date"
                    rules="required"
                    vid="closeDate"
                  >
                    <flat-pickr
                      id="closeDate"
                      v-model="questionForm.close_date"
                      name="closeDate"
                      class="form-control"
                      :config="{ minuteIncrement: 1, enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                class="mt-2 text-right"
              >
                <!-- cancel button -->
                <b-button
                  variant="outline-secondary"
                  type="button"
                  class="mr-1"
                  @click="handleModalCancel"
                >
                  Cancel
                </b-button>

                <!-- action button -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="invalid || !dirty"
                >
                  Update Question
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  BAlert,
  BBadge, BButton,
  BCard, BCardText,
  BDropdown, BDropdownItem,
  BForm, BFormInput, BFormGroup, BFormSelect,
  BInputGroup, // BInputGroupAppend,
  BLink,
  BPagination, BTable,
  BModal,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BLink,
    BPagination,
    BTable,
    BModal,

    flatPickr,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      adminAccess: false,
      adminSuffix: '', // 'AsRoot' if root user

      perPage: 10,
      pageOptions: [5, 10, 15, 30, 45, 75],
      // totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'text', label: 'Text', sortable: true },
        { key: 'open_date', label: 'Open Date', sortable: true },
        { key: 'cutoff_date', label: 'Cutoff Date', sortable: true },
        { key: 'close_date', label: 'Close Date', sortable: true },
        { key: 'correct_response', label: 'Correct Response', sortable: true },
        { key: 'closed', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      statusColor: {
        approved: 'light-success',
        flagged: 'light-warning',
        new: 'light-info',
        rejected: 'light-danger',
      },
      closedStatusColor: {
        false: 'light-success',
        true: 'light-danger',
      },
      errorMessage: null,

      // edit question form
      questionForm: {},

      // validation
      required,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    visibleQuestions() {
      const digestData = this.$store.getters['questions/getAllQuestions']
      return digestData ? digestData.data : []
    },
    totalTableRows() {
      const digestData = this.$store.getters['questions/getAllQuestions']
      const linksData = digestData ? digestData.links : 0
      return linksData ? linksData.current_size * linksData.last_page : 0
    },
  },
  created() {
    this.getQuestions()
  },
  methods: {
    getQuestions(args) {
      // set table page and size
      const questionArgs = {
        page: this.currentPage,
        size: this.perPage,
        ...args,
      }

      // get questions digest
      this.$store.dispatch('questions/fetchAllQuestions', questionArgs)
    },

    /* QUESTION MANAGEMENT */

    handleItemTrue(questionId) {
      // confirm alert
      this.$swal({
        title: 'Set as True?',
        text: 'This question\'s answer will be set to TRUE.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.setQuestionTrue(
            questionId,
          )
            .then(response => {
              const responseData = response
              console.log('setQuestionTrue', responseData)

              // confirm message
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'Your question has been set as TRUE.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.go(0) // reload page
            })
            .catch(error => {
              console.log(error.response.data)
              const errorMessage = error.response.data.error || 'This request did not go through.'

              // error message
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: `${errorMessage}`,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            })
        }
      })
    },
    handleItemFalse(questionId) {
      // confirm alert
      this.$swal({
        title: 'Set as False?',
        text: 'This question\'s answer will be set to FALSE.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.setQuestionFalse(
            questionId,
          )
            .then(response => {
              const responseData = response
              console.log('setQuestionFalse', responseData)

              // confirm message
              this.$swal({
                icon: 'success',
                title: 'Done!',
                text: 'Your question has been set as FALSE.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$router.go(0) // reload page
            })
            .catch(error => {
              console.log(error.response.data)
              const errorMessage = error.response.data.error || 'This request did not go through.'

              // error message
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: `${errorMessage}`,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            })
        }
      })
    },
    handleItemEdit(questionData) {
      this.questionForm = {
        ...questionData,
        open_date: this.convertToLocalTime(questionData.open_date),
        cutoff_date: this.convertToLocalTime(questionData.cutoff_date),
        close_date: this.convertToLocalTime(questionData.close_date),
      }
      this.$bvModal.show('modal-edit-question')
    },
    handleModalCancel() {
      this.$bvModal.hide('modal-edit-question')
      this.questionForm = {}
    },
    handleModalSubmit() {
      const questionFormData = {
        ...this.questionForm,
        open_date: this.convertToUTC(this.questionForm.open_date),
        cutoff_date: this.convertToUTC(this.questionForm.cutoff_date),
        close_date: this.convertToUTC(this.questionForm.close_date),
      }
      console.log('questionFormData', questionFormData)

      useJwt.updateQuestion(questionFormData.id, questionFormData)
        .then(res => {
          const responseCode = res.status
          const responseText = res.statusText

          console.log('updateQuestion', res)
          console.log('Server reponse: ', responseCode, responseText)
          // this.message = `${responseCode} ${responseText}`
          this.message = `${responseText}`

          // show notification
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Question Updated',
              icon: 'QuestionPlusIcon',
              variant: 'success',
              text: `You have updated a question: "${this.questionForm.text}"`,
            },
          })
          this.$bvModal.hide('modal-edit-question')
          this.$router.go(0) // reload page
        })
        .catch(err => {
          console.log('AddQuestion ERROR', err.response.status, err.response.statusText, err.response.data)

          const errorResponse = err.response.data.data
          const errorMessage = errorResponse.error ? errorResponse.error : errorResponse

          // Error notification on failure
          this.$swal({
            title: 'Error',
            text: `${errorMessage}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },

    /* TABLE FILTER AND PAGINATION */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.perPage = filteredItems.length
      this.currentPage = 1

      this.getQuestions()
    },
    handlePaginationClick(bvEvent, currentPage) {
      const conArgs = {
        page: currentPage,
      }
      this.getQuestions(conArgs)
    },
    handlePerPage(sizeValue) {
      const conArgs = {
        size: sizeValue,
      }
      this.getQuestions(conArgs)
    },

    /* MISCELLANEOUS */

    convertToLocalTime(dateStr) {
      // convert utc date to local time
      const utcDate = moment.utc(dateStr)
      const convertedDate = moment(utcDate).local().format('YYYY-MM-DD h:mm A')
      return convertedDate
    },
    convertToUTC(dateStr) {
      // convert date to UTC
      const localDate = moment(dateStr).local()
      const convertedDate = moment.utc(localDate).format('YYYY-MM-DD HH:mm')
      return convertedDate
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
